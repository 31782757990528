import request from './client';

class UserAPI {
     login = ({ username, password}) => {
        return request.post('login', { username, password});
    }

    logout = () => {
        return request.post('logout');
    }
    getMe = () => {
        return request.post('me');
    }
    getToken = ({code, state ,device}) => {
         return request.post('get-token', { code, state ,device});
    }

}

export default new UserAPI;