<template>
      <!-- ======= Sidebar ======= -->
  <aside id="sidebar" class="sidebar z-999">

    <ul class="sidebar-nav" id="sidebar-nav">

      <li class="nav-item" v-if="checkMenuOpened('Dashboard')">
        <router-link class="nav-link collapsed" :to="{name: 'Home'}" :class="{'select-link': checkRouteOpenMenu(['Home'])}">
          <i class="bi bi-grid"></i>
          <span>Dashboard</span>
        </router-link>
      </li><!-- End Dashboard Nav -->

      <li class="nav-item" id="nav-item-profile" v-if="checkMenuOpened('Profile')">
        <a
          class="nav-link"
          :class="{collapsed: !checkRouteOpenMenu(routerProfile)}"
          data-bs-target="#components-nav-profile"
          data-bs-toggle="collapse" href="#"
        >
          <i class="bi bi-person-fill"></i><span>{{ $t("Sidebar.Profile") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
          id="components-nav-profile"
          class="nav-content collapse"
          :class="{show: checkRouteOpenMenu(routerProfile)}"
          data-bs-parent="#nav-item-profile"
        >
          <li :class="{'select-link': checkRouteOpenMenu(['Profile'])}" v-if="checkMenuOpened('ProfileInfo')">
            <router-link :to="{name: 'Profile'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.ProfileInfo") }}</span>
            </router-link>
          </li>
          <li
            v-if="checkPermission(user, 'canViewListProfile') && checkMenuOpened('ProfileManagement')"
            :class="{'select-link': checkRouteOpenMenu(['ProfileList', 'CreateProfile', 'EditProfile', 'ViewProfile'])}"
          >
            <router-link :to="{name: 'ProfileList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.ProfileManagement") }} </span>
            </router-link>
          </li>
          <li :class="{'select-link': checkRouteOpenMenu(['AccountSecurity'])}" v-if="checkMenuOpened('AccountSecurity')">
            <router-link :to="{name: 'AccountSecurity'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.AccountSecurity") }}</span>
            </router-link>
          </li>
          
        </ul>
      </li><!-- profile -->

      <li class="nav-item" id="nav-item-balance" v-if="(checkPermission(user, 'canViewBalanceFluctuationsStatistics') ||
      checkPermission(user, 'canViewMerchantDisbursementLog') || checkPermission(user, 'canViewMerchantUsdLog')) && checkMenuOpened('BalanceFluctuationsStatistics')"><!-- Balance -->
        <a
          class="nav-link"
          :class="{collapsed: !checkRouteOpenMenu(routeBalance)}"
          data-bs-target="#components-nav-balance"
          data-bs-toggle="collapse" href="#"
        >
          <i class="bi bi-graph-up"></i><span>{{ $t("Sidebar.BalanceFluctuationsStatistics") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
          id="components-nav-balance"
          class="nav-content collapse"
          :class="{show: checkRouteOpenMenu(routeBalance)}"
          data-bs-parent="#nav-item-profile"
        >
          <li
              v-if="checkPermission(user, 'canViewBalanceFluctuationsStatistics') && checkMenuOpened('StatisticsOfBalanceFluctuations')"
              :class="{'select-link': checkRouteOpenMenu(['MerchantBalanceLog'])}"
          >
            <router-link :to="{name: 'MerchantBalanceLog'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.StatisticsOfBalanceFluctuations") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewMerchantDisbursementLog') && checkMenuOpened('StatisticsOfAvailableBalanceFluctuations')"
              :class="{'select-link': checkRouteOpenMenu(['MerchantDisbursementLog'])}"
          >
            <router-link :to="{name: 'MerchantDisbursementLog'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.MerchantDisbursementLog") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewMerchantUsdLog') && checkMenuOpened('StatisticsOfAvailableBalanceUsd')"
              :class="{'select-link': checkRouteOpenMenu(['MerchantUsdLog'])}"
          >
            <router-link :to="{name: 'MerchantUsdLog'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.MerchantUsdLog") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- Balance -->

      <li class="nav-item" id="nav-item-recharge-information" v-if="(checkPermission(user, 'canViewTopUpAvailableBalance') ||
      checkPermission(user, 'canViewAccountDisbursementList') || checkPermission(user, 'canViewAccountMerchantUsd')) && checkMenuOpened('Recharge')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerRechargeInformation)}"
            data-bs-target="#components-nav-recharge-information"
            data-bs-toggle="collapse" href="#">
          <i class="bi bi-cash-coin"></i><span>{{ $t("Sidebar.RechargeInformation") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav-recharge-information"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerRechargeInformation)}"
            data-bs-parent="#nav-item-recharge-information">
          <li v-if="checkPermission(user, 'canViewTopUpAvailableBalance') && checkMenuOpened('TopUpAvailableBalance')"
              :class="{'select-link': checkRouteOpenMenu(['TopUpAvailableBalance'])}">
            <router-link :to="{name: 'TopUpAvailableBalance'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.TopUpAvailableBalance") }}</span>
            </router-link>
          </li>
          <li v-if="checkPermission(user, 'canViewAccountDisbursementList') && checkMenuOpened('AccountDisbursementList')"
              :class="{'select-link': checkRouteOpenMenu(['AccountDisbursementList'])}">
            <router-link :to="{name: 'AccountDisbursementList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.AccountDisbursementList") }} </span>
            </router-link>
          </li>
          <li v-if="checkPermission(user, 'canViewAccountMerchantUsd') && checkMenuOpened('DetailOfUsdBalanceTopUpAccount')"
              :class="{'select-link': checkRouteOpenMenu(['MerchantUsdAccount'])}">
            <router-link :to="{name: 'MerchantUsdAccount'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.MerchantUsdAccount") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- Recharge -->

      <li class="nav-item" v-if="checkActiveBalanceModule() && checkPermission(user, 'canWithdrawBalance') && checkMenuOpened('Withdraw')">
        <router-link class="nav-link collapsed" :to="{name: 'WithdrawBalance'}" :class="{'select-link': checkRouteOpenMenu(['WithdrawBalance'])}">
        <i class="bi bi-wallet"></i><span>{{ $t("Sidebar.Withdraw") }}</span>
        </router-link>
      </li><!-- Withdraw -->

      <li class="nav-item" v-if="(checkMerchantActiveDisbursement(user)
      && checkPermission(user, 'canAddMerchantRequestDisbursement'))
      && checkMenuOpened('TransferMoney')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerTransactionDisbursement)}"
            data-bs-target="#components-nav-disbursement"
            data-bs-toggle="collapse"
            href="#"
        >
          <i class="bi bi-cash-stack"></i><span>{{ $t("Sidebar.TransactionDisbursementManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav-disbursement"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerTransactionDisbursement)}"
            data-bs-parent="#nav-item-transaction"
        >
          <li
              v-if="checkPermission(user, 'canAddMerchantRequestDisbursement') && checkMenuOpened('TransferMoney247')"
              :class="{'select-link': checkRouteOpenMenu(['MerchantRequestDisbursement', 'DisbursementByBatchConfirm'])}"
          >
            <router-link :to="{name: 'MerchantRequestDisbursement'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.MerchantRequestDisbursement") }} </span>
            </router-link>
          </li>
          <li
            v-if="checkActiveModuleByBatch() && checkPermission(user, 'canAddMerchantRequestDisbursement') && checkMenuOpened('DisbursementByBatchList')"
            :class="{'select-link': checkRouteOpenMenu(['DisbursementByBatchList', 'DisbursementByBatchDetail'])}"
          >
            <router-link :to="{name: 'DisbursementByBatchList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.DisbursementByBatchList") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- transfer -->

      <li class="nav-item" id="nav-item-transaction" v-if="checkPermission(user, ['canViewListTransaction', 'canViewTransactionDisbursementList', 'canViewListRefund', 'canViewListRefundInstallmentRequest'])
      && checkMenuOpened('TransactionManagement')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerTransaction)}"
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="#"
        >
          <i class="bi bi-menu-button-wide"></i><span>{{ $t("Sidebar.TransactionManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerTransaction)}"
            data-bs-parent="#nav-item-transaction"
        >
          <li
              v-if="checkPermission(user, 'canViewListTransaction') && checkMenuOpened('TransactionList')"
              :class="{'select-link': checkRouteOpenMenu(['ListTransaction', 'TransactionDetail'])}"
          >
            <router-link :to="{name: 'ListTransaction'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.TransactionList") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewTransactionDisbursementList') && checkMenuOpened('TransactionDisbursement')"
              :class="{'select-link': checkRouteOpenMenu(['TransactionDisbursementList', 'TransactionDisbursementDetail'])}"
          >
            <router-link :to="{name: 'TransactionDisbursementList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.TransactionDisbursement") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewListRefund') && checkMenuOpened('RefundManagement')"
              :class="{'select-link': checkRouteOpenMenu(['Refund', 'RefundDetail', 'CreateRefund', 'UpdateRefund'])}"
          >
            <router-link :to="{name: 'Refund'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.RefundManagement") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewListRefundInstallmentRequest') && checkMenuOpened('RefundInstallmentRequestManage')"
              :class="{'select-link': checkRouteOpenMenu(['RefundInstallmentReqList'])}"
          >
            <router-link :to="{name: 'RefundInstallmentReqList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.RefundInstallmentRequestManage") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- trans management -->

      <li class="nav-item" id="nav-item-invoice" v-if="user.profile.invoice && checkPermission(user, ['canViewListInvoice']) && checkMenuOpened('InvoiceManagement')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerInvoice)}"
            data-bs-target="#components-nav-invoice"
            data-bs-toggle="collapse"
            href="#"
        >
          <i class="bi bi-journal-richtext"></i><span>{{ $t("Sidebar.InvoiceManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav-invoice"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerInvoice)}"
            data-bs-parent="#nav-item-invoice"
        >
          <li
              v-if="checkPermission(user, 'canAddInvoice') && !checkIsMasterMerchant(user) && checkMerchantActive(user)  && checkMenuOpened('AddInvoice')"
              :class="{'select-link': checkRouteOpenMenu(['CreateInvoice'])}"
          >
            <router-link :to="{name: 'CreateInvoice'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.AddInvoice") }}</span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewListInvoice') && checkMenuOpened('InvoiceList')"
              :class="{'select-link': checkRouteOpenMenu(['InvoiceList', 'EditInvoice', 'ViewInvoice'])}"
          >
            <router-link :to="{name: 'InvoiceList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.InvoiceList") }}</span>
            </router-link>
          </li>
        </ul>
      </li><!-- invoice -->

      <li class="nav-item" id="nav-item-mpos" v-if="user.profile.invoice && checkPermission(user, ['canViewListInvoice']) && checkMenuOpened('MposManagement')">
          <a
              class="nav-link"
              :class="{collapsed: !checkRouteOpenMenu(routerMpos)}"
              data-bs-target="#components-nav-mpos"
              data-bs-toggle="collapse" href="#"
          >
            <i class="bi bi-shop-window"></i><span>{{ $t("Sidebar.MposManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
              id="components-nav-mpos"
              class="nav-content collapse"
              :class="{show: checkRouteOpenMenu(routerMpos)}"
              data-bs-parent="#nav-item-mpos"
          >
            <li
                v-if="checkPermission(user, 'canViewPaymentLinkInfo') && checkMenuOpened('MposInformation')"
                :class="{'select-link': checkRouteOpenMenu(['MposManagement'])}"
            >
              <router-link :to="{name: 'MposManagement'}">
                <i class="bi bi-circle"></i><span>{{ $t("Sidebar.MposInformation") }} </span>
              </router-link>
            </li>
          </ul>
        </li><!-- Mpos -->

      <li class="nav-item" id="nav-item-va" v-if=" checkActiveModuleQR() && checkMerchantActiveVA(user) && (checkPermission(user, ['canViewQRList']) ||
      checkPermission(user, 'canViewNotificationQR') || checkPermission(user, 'canViewListTransactionsQR')) && checkMenuOpened('StoreQRManagement')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerQR)}"
            data-bs-target="#components-nav-va"
            data-bs-toggle="collapse"
            href="#">
          <i class="bi bi-upc-scan"></i><span>{{ $t("Sidebar.QRManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav-va"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerQR)}"
            data-bs-parent="#nav-item-va">
          <li :class="{'select-link': checkRouteOpenMenu(['QRDashboard'])}" v-if="checkMenuOpened('QRDashboard')">
            <router-link :to="{name: 'QRDashboard'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.QRDashboard") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewQRList') && checkMenuOpened('QRList')"
              :class="{'select-link': checkRouteOpenMenu(['QRList'])}"
          >
            <router-link :to="{name: 'QRList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.QRList") }} </span>
            </router-link>
          </li>
          <li v-if="checkPermission(user, 'canViewListTransactionsQR') && checkMenuOpened('QRtransactions')"
              :class="{'select-link': checkRouteOpenMenu(['QRTransactions'])}"
          >
            <router-link :to="{name : 'QRTransactions'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.QRtransactions") }} </span>
            </router-link>
          </li>
          <li v-if="checkPermission(user, 'canViewNotificationQR') && checkMenuOpened('QRnotify')"
              :class="{'select-link': checkRouteOpenMenu(['QRnotify'])}"
          >
            <router-link :to="{name: 'QRnotify'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.QRnotify") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- store qr -->

      <li class="nav-item" v-if="checkMerchantActiveSDKWallet(user) && checkPermission(user, ['canViewWalletBalanceFluctuate', 'canViewUserOrderHistory'])
      && checkMenuOpened('WalletManagement')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerWallet)}"
            data-bs-target="#components-nav-wallet"
            data-bs-toggle="collapse"
            href="#"
        >
          <i class="bi bi-wallet-fill"></i><span>{{ $t("Sidebar.WalletManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav-wallet"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerWallet)}"
            data-bs-parent="#nav-item-transaction"
        >
          <!--          <li-->
          <!--            v-if="checkPermission(user, 'canViewWalletBalanceFluctuate')"-->
          <!--            :class="{'select-link': checkRouteOpenMenu(['BalanceLogWallet'])}"-->
          <!--          >-->
          <!--            <router-link :to="{name: 'BalanceLogWallet'}">-->
          <!--              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.WalletBalanceFluctuate") }} </span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li
              v-if="checkPermission(user, 'canViewUserOrderHistory') && checkMenuOpened('OrdersHistoryOfUser')"
              :class="{'select-link': checkRouteOpenMenu(['OrdersHistoryWallet'])}"
          >
            <router-link :to="{name: 'OrdersHistoryWallet'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.OrdersHistoryOfUser") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- SDKWallet -->

      <li class="nav-item" v-if="checkMerchantActiveSchoolPayment(user) && checkPermission(user, ['canViewOrderPaymentPageList', 'canViewListPaymentPurpose'])
      && checkMenuOpened('PaymentSchoolManagement')">
        <a
            class="nav-link"
            :class="{collapsed: !checkRouteOpenMenu(routerSchoolPayment)}"
            data-bs-target="#components-nav-school"
            data-bs-toggle="collapse"
            href="#"
        >
          <i class="bi bi-shop"></i><span>{{ $t("Sidebar.PaymentSchoolManagement") }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul
            id="components-nav-school"
            class="nav-content collapse"
            :class="{show: checkRouteOpenMenu(routerSchoolPayment)}"
            data-bs-parent="#nav-item-transaction"
        >
          <li
              v-if="checkPermission(user, 'canViewOrderPaymentPageList') && checkMenuOpened('OrderPaymentList')"
              :class="{'select-link': checkRouteOpenMenu(['SchoolPaymentList', 'SchoolPaymentDetail'])}"
          >
            <router-link :to="{name: 'SchoolPaymentList'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.OrderPaymentList") }} </span>
            </router-link>
          </li>
          <li
              v-if="checkPermission(user, 'canViewListPaymentPurpose') && checkMenuOpened('PaymentPurpose')"
              :class="{'select-link': checkRouteOpenMenu(['PaymentPurpose'])}"
          >
            <router-link :to="{name: 'PaymentPurpose'}">
              <i class="bi bi-circle"></i><span>{{ $t("Sidebar.PaymentPurpose") }} </span>
            </router-link>
          </li>
        </ul>
      </li><!-- SchoolPayment -->
    </ul>
  </aside><!-- End Sidebar-->
</template>

<script>
import { mapGetters } from 'vuex';
import helper from '../until/helper';
import AppConstant from '../constants/App';
import UserTypeConstant from "../constants/UserType";
import {getDatabase, onValue, ref} from "firebase/database";
import store from "@/store";

export default {
  name: "Sidebar",
  components: {
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      profileMerchant: "authentications/getProfileMerchant",
      menu: "authentications/getMenu"
    })
  },
  mounted() {
    this.listenMenuChanged(this.menu, this.user.profile.merchant_id)
  },
  data() {
    return {
      routerProfile: [
        'Profile',
        'ProfileList',
        'CreateProfile',
        'EditProfile',
        'ViewProfile',
        'AccountSecurity',
      
      ],
      routeBalance: [
        // 'WithdrawBalance'
          'MerchantBalanceLog',
          'MerchantDisbursementLog',
          'MerchantUsdLog',
      ],
      routerInvoice: [
        'InvoiceList',
        'CreateInvoice',
        'EditInvoice',
        'ViewInvoice',
      ],
      routerTransaction: [
        'ListTransaction',
        'TransactionDetail',
        'TransactionDisbursementList',
        'TransactionDisbursementDetail',
        'Refund',
        'RefundInstallmentReqList'
        // 'MerchantBalanceLog',
      ],
      routerSchoolPayment: [
        'SchoolPaymentList',
        'SchoolPaymentDetail',
        'PaymentPurpose',
        'CreatePaymentPurpose',
        'EditPaymentPurpose',
      ],
      routerTransactionDisbursement: [
        // 'TransactionDisbursementList',
        // 'TransactionDisbursementDetail',
        // 'MerchantDisbursementLog',
        'MerchantRequestDisbursement',
        'DisbursementByBatchList',
        'DisbursementByBatchConfirm',
        'DisbursementByBatchDetail'
      ],
      routerWallet: [
        'BalanceLogWallet',
        'OrdersHistoryWallet',
      ],
      routerMpos: [
        'MposManagement',
      ],
      routerRefund: [
          // 'Refund',
          // 'RefundInstallmentReqList'
      ],

      routerRechargeInformation: [
          'TopUpAvailableBalance',
          'AccountDisbursementList',
          'MerchantUsdAccount',

      ],

      routerQR: [
          'QRList',
          'QRTransactions',
          'QRnotify',
          'QRDashboard'
      ],
      AppConstant: AppConstant,
      firstTimeLogin: 0
    }
  },
  methods: {
    isset: helper.isset,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    checkMerchantActiveSchoolPayment: helper.checkMerchantActiveSchoolPayment,
    checkMerchantActiveSDKWallet: helper.checkMerchantActiveSDKWallet,
    checkMerchantActiveDisbursement: helper.checkMerchantActiveDisbursement,
    checkMerchantActiveVA: helper.checkMerchantActiveVA,
    checkShowDisbursementRequest: helper.checkShowDisbursementRequest,
    checkRouteOpenMenu(routes) {
      if (routes.includes(this.$route.name)) {
        return true;
      }
      return false;
    },
    checkActiveModuleQR(){
      if (typeof this.profileMerchant.is_store_qr !== 'undefined' && this.profileMerchant.is_store_qr === 1) {
        return true
      } else {
        return false
      }
    },
    checkActiveBalanceModule() {
      if (typeof this.profileMerchant.merchant_profile_extra !== 'undefined' && this.profileMerchant.merchant_profile_extra.receive_money_type == 2) {
        return true;
      }
      return false;
    },
    checkMenuOpened(tabCode){
      if (parseInt(this.user.type) === UserTypeConstant.MASTER_MERCHANT || parseInt(this.user.type) === UserTypeConstant.SUB_MASTER_MERCHANT){
        return true;
      }
      if (this.menu.includes(tabCode)){
        return true;
      }else{
        return false;
      }
    },
    checkActiveModuleByBatch(){
      if (typeof this.profileMerchant.merchant_profile_extra !== 'undefined' && this.profileMerchant.merchant_profile_extra.is_disbursement_by_batch == 1) {
        return true
      } else {
        return false
      }
    },
    listenMenuChanged(menu, merchantID) {
      const db = getDatabase();
      const starCountRef = ref(db, process.env.VUE_APP_SERVER + 'v2/menu/menu_' + merchantID);
      let userLocalStorage = JSON.parse(localStorage.getItem('user'));
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (helper.isset(data) && Object.keys(data).length > 0 && helper.isset(data.menu)) {
          let differenceMenu = this.checkMenuWasChanged(data.menu, menu);
          if (this.firstTimeLogin > 0 && differenceMenu && typeof userLocalStorage.profile !== 'undefined') {
            console.log('updating menu')
            localStorage.setItem('user', JSON.stringify({...userLocalStorage, menu: data.menu}));
            store.commit('authentications/CHANGE_MENU', data.menu)
          }
          this.firstTimeLogin += 1;
        }
      });
    },
    checkMenuWasChanged(a, b) {
      if (a.length !== b.length){
        return true;
      }
      let difference = a.filter((element) => !b.includes(element));

      return difference.length > 0 ? true : false;
    }
  }
};

</script>

<style>
  ul li a {
    text-decoration: none;
  }
  .select-link {
    background-color: #f6f9ff !important;
  }
</style>
