<template>
    <div>
        <a-form-model
            layout="vertical"
            ref="refFormByBatch"
            :model="formDataByBatch"
        >
            <a-row :gutter="[16,16]">
                <a-col :lg="12" :xs="24">
                    <a-row>
                        <a-col>
                            <a-form-model-item
                                prop="fileByBatch"
                            >
                                <span slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.ChooseFile') }} (<span class="text-danger">*</span>)</span>
                                <a-upload-dragger
                                    name="file"
                                    accept=".xlsx"
                                    :multiple="false"
                                    :customRequest="uploadFileByBatch"
                                    :before-upload="beforeUploadFileByBatch"
                                    :file-list="termFileList"
                                    :remove="handleRemoveUploadByBatch"
                                    @change="handleUploadByBatch"
                                >
                                    <div class="d-flex align-items-center justify-content-center">
                                        <p class="ant-upload-drag-icon">
                                            <a-icon class="icon-drag" type="inbox" />
                                        </p>
                                        <p class="ant-upload-text">{{ $t('Disbursement.ChooseFileText') }} <a href="javascript:;">{{ $t('Disbursement.ChooseFileTextLink') }}</a> {{ $t('Disbursement.ChooseFileTextLimit') }}</p>
                                    </div>
                                </a-upload-dragger>
                                <a-button class="mt-3 download-simple" :href="fileUrl" download="Template_chuyen_tien.xlsx" type="primary"><a-icon class="icon-download" type="cloud-download" />{{ $t('Disbursement.Download') }}</a-button>
                                <div class="disbursement-note mt-4">
                                    <div><span class="title-note text-danger">{{ $t('Disbursement.Note') }}:</span> {{ $t('Disbursement.NoteBank') }}</div>
                                </div>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-button v-if="!checkIsMasterMerchant(user)" type="primary w-100" class="btn-submit__file" @click="onSubmitByBatch" :disabled="checkByBatch || loading">
                        <span v-if="loading" class="spinner-border"></span>
                        {{ $t('Disbursement.ButtonCheck') }}
                    </a-button>
                </a-col>
            </a-row>
        </a-form-model>

        <a-modal v-model="visibleModal" footer :closable="false"  :width="435" id="modal-alert" :maskClosable="false">
            <div class="alert-content mb-2">
                <div class="alert-icon alert-icon__warning mb-3">
                    <i class="bi bi-exclamation-triangle-fill"></i>
                </div>
                <div class="alert-message text-center">
                    <p class="mb-3 font-svn__poppins__medium">{{ txtMessage }}</p>
                </div>
            </div>
            <div class="alert-footer text-center">
                <a-button id="btn-close-alert" @click="closeAlertModal">{{ $t('Balance.ButtonTitle.close') }}</a-button>
            </div>
        </a-modal>
    </div>
</template>
  
<script>

import { mapGetters } from 'vuex';
import CallApi from '../../../services/api.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import helper from '../../../until/helper';

const currencyMaskVND = createNumberMask({
  prefix: '',
  allowDecimal: false
});

const codeMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true
});

export default {
    name: 'DisbursementByBatch',
    components: {},
    data() {

        let checkFileByBatch = (rule, value, callback) => {
            const checkType = this.formDataByBatch.fileByBatch.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!checkType) {
                callback(new Error(this.$t('Message.UploadTypeNotAccept', {name: this.$t('Disbursement.FileUpload')})));
            } else if (this.formDataByBatch.fileByBatch.size / 1024 / 1024 > 1) {
                callback(new Error(this.$t('Message.UploadSizeMax1MB', {name: this.$t('Disbursement.FileUpload')})));
            } else {
                callback();
            }
        }

        return {
            formDataByBatch: {
                fileByBatch: ''
            },
            ruleByBatch: {
                fileByBatch: [
                    {
                        required: true,
                        message: this.$t('Message.Required', {'name': this.$t('Disbursement.FileUpload')}),
                        trigger: 'blur'
                    },
                    {
                        validator: checkFileByBatch,
                        trigger: 'blur'
                    },
                ]
            },
            fileUrl: '/assets/files/Template_chuyen_tien.xlsx',
            loading: false,
            termFileList: [],
            checkByBatch: true,
            currencyMaskVND: currencyMaskVND,
            codeMask: codeMask,
            visibleModal: false,
            txtMessage: '',
            columns: [
                {
                    key: "line",
                    slots: { title: "lineMessage" },
                    scopedSlots: { customRender: "line" },
                    width: "15%",
                    align: "center",
                },
                {
                    key: "description",
                    dataIndex: "description",
                    slots: { title: "descriptionMessage" },
                    scopedSlots: { customRender: "description" }
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            user: 'authentications/getUserLogin',
            merchant: 'authentications/getMerchants',
        }),
    },
    mounted() {
        
    },
    created() {

    },
    methods: {
        isset: helper.isset,
        checkIsMasterMerchant: helper.checkIsMasterMerchant,
        showMoneyFormat: helper.showMoneyFormat,
        closeAlertModal() {
            this.visibleModal = false;
            this.termFileList = [];
            this.checkByBatch = true;
        },
        handleRemoveUploadByBatch() {
            this.checkByBatch = true;
        },
        uploadFileByBatch() {
            return false;
        },
        beforeUploadFileByBatch(file) {
            this.termFileList = [];
            file.status = "success";
            const checkType = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            if (!checkType) {
                this.txtMessage = this.$t('Disbursement.Mimes');
                this.visibleModal = true;
            }
            
            const isLt1M = file.size / 1024 / 1024 <= 1;
            if (!isLt1M) {
                this.txtMessage = this.$t('Disbursement.Sizes');
                this.visibleModal = true;
            }

            if(checkType && isLt1M) {
                this.checkByBatch = false;
            }

            return checkType && isLt1M;
        },
        onSubmitByBatch() {
            this.loading = true;
            
            this.$refs.refFormByBatch.validate(valid => {
                
                if(valid) {
                    let dataInput = new FormData();

                    if (this.formDataByBatch.fileByBatch != null && this.termFileList.length > 0) {
                        dataInput.append('file_by_batch', this.formDataByBatch.fileByBatch.originFileObj);
                    }
                    
                    CallApi.createRequestByBatch(dataInput).then((response) => {
                        if(response.code) {
                            if(response.code === 422) {
                                if(response.errors.file_by_batch[0] == "validation.mimes" || response.errors.file_by_batch[0] == "validation.required") {
                                    this.txtMessage = this.$t('Disbursement.Mimes');
                                }

                                if(response.errors.file_by_batch[0] == "validation.max") {
                                    this.txtMessage = this.$t('Disbursement.Sizes');
                                }
                                
                                this.visibleModal = true;
                            } else {

                                if(response.errors === 'NotEnableWithdraw' || response.errors === 'NotEnableDisbursement') {
                                    this.showNotice('error', this.$t('Notice.Message.' + response.errors), '');
                                } else {
                                    if(this.isset(response.errors.mimes)) {
                                        this.txtMessage = this.$t('Disbursement.Mimes');
                                        this.visibleModal = true;
                                    }
                                }

                                this.termFileList = [];
                                this.checkByBatch = true;
                                
                            }
                        } else {
                            let historyId = response.data.history_id;
                            this.$router.push({name: 'DisbursementByBatchConfirm', params: { id: historyId } });
                        }

                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                        this.checkByBatch = true;
                        this.termFileList = [];
                        this.showNotice('error', this.$t('Disbursement.Validate.FileUpload'), '');
                    });
                }
                
            });
        },
        showNotice(type, title, content) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: 5,
                style: {
                    "margin-top": "50px"
                },
            });
        },
        handleUploadByBatch(info) {
            info.file.status = "success";
            this.termFileList = info.fileList;
            this.formDataByBatch.fileByBatch = info.file;
            if (this.isset(this.$refs.fileByBatch) && this.$refs.fileByBatch.validateState == "error") {
                this.$refs.fileByBatch.clearValidate();
            }
            
        }
    },
    beforeMount() {
        
    }
}
</script>
  