<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>
    <a-card class="mb-30 shadow">
        <p class="mb-0">{{ $t('Balance.Title.availableBalance') }}: <span class="fw-bold">{{ showMoneyFormat(balance) }} VND</span> </p>
    </a-card>
    <a-card class="mb-30 shadow">
       <div id="components-form" style="max-width: 600px" ref="mainContent" :inert="visibleModalRegister ? 'true' : null" :aria-hidden="visibleModalRegister ? 'true' : 'false'">
          <a-form-model
            ref="formRef"
            :model="formState"
            id="frm-wrapper"
          >
              <a-form-model-item prop="accountNo">
                <span slot="label">{{ $t('Balance.Title.accountNo') }} (<span class="text-danger">*</span>)
                </span>
                <a-select @change="onChangeAccount($event)" v-model="formState.accountId" :placeholder="$t('Balance.Placeholder.chosseAccountNo')" >
                  <a-select-option :value="item.id"  v-for="(item, index) in accounts" :key="index">
                    {{ item.account_number }} - {{  item.account_name}} - {{ item.bank_name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>


              <a-form-model-item v-if="formState.accountNo">
                <span slot="label">{{ $t('Balance.Title.accountName') }}</span>
                <a-input v-model="formState.accountName"  :maxLength="255" :disabled="true"/>
              </a-form-model-item>

              <a-form-model-item v-if="formState.accountNo">
                <span slot="label">{{ $t('Balance.Title.bank') }}</span>
                <a-input v-model="formState.bank"  :maxLength="255" :disabled="true"/>
              </a-form-model-item>

              <a-form-model-item  prop="amountDisplay">
                <span slot="label">{{ $t('Balance.Title.amount') }} (<span class="text-danger">*</span>)</span>
                <a-input id="input-amount" 
                v-model="amountDisplay"
                :max-length=20
                suffix="VND"
                @blur="resetAmountFixex"
                @keypress="onlyAmount"
                v-mask="currencyMask"
                :placeholder="$t('Balance.Placeholder.amountMin')"
               />
                <span id="btn-all-amount" @click="setMaxBalance">{{ $t('Balance.ButtonTitle.all') }}</span>
              </a-form-model-item>
              <a-form-model-item prop="description">
                <span slot="label">{{ $t('Balance.Title.description') }} (<span class="text-danger">*</span>)</span>
                <a-input v-model="formState.description" :maxLength="255" @keypress="validDescription" @blur="handleDescription" :placeholder="$t('Balance.Placeholder.description')"/>
              </a-form-model-item>
              <!-- <p v-if="!isEnableAuth">{{ $t('Balance.Title.descriptionOtp')}} <a :href="'/account/security'" class="fw-bold">{{ $t('Security.TextRegisterNow') }}</a></p> -->
              
              <a-form-item>
                <a-button class="w-100 fw-bold" type="primary" @click="onSubmit" :disabled="disabledSubmit || loading">
                  <a-spin v-if="loading" class="me-2 pt-1"/>
                  {{ $t('Balance.ButtonTitle.withdraw') }}
                  </a-button>
              </a-form-item>
          </a-form-model>
        </div>
    </a-card>
    <a-modal v-model="visibleModal" footer :closable="false"  :width="435" id="modal-alert" :maskClosable="false">
      <div class="alert-content mb-2">
        <div class="alert-icon">
              <i class="bi bi-check-circle"></i>
              <p class="alert-status">{{ $t('Balance.Alert.success') }}</p>
        </div>
        <div class="alert-message text-center">
          <p class="mb-1">{{ $t('Balance.Alert.successDescriptionOne') }}</p>
          <p class="mb-1">{{ $t('Balance.Alert.sucessDescriptionTwo') }}</p>
          <p>{{ $t('Balance.Alert.successDescriptionThree') }}</p>
        </div>
      </div>
      <div class="alert-footer text-center">
          <a-button id="btn-close-alert" class="me-5" @click="closeAlertModal">{{ $t('Balance.ButtonTitle.close') }}</a-button>
         <a-button id="btn-show-transaction" @click="handleClickViewTransaction">
          {{ $t('Balance.ButtonTitle.viewTransaction') }}
        </a-button>
  
      </div>
    </a-modal>

    <PopupRegisterAuth :popupVisible="popupRequestVisible"></PopupRegisterAuth>

    <OtpForm 
      :accounts="user.accounts"
      :visibleModal="visibleModalRegister"
      @close-modal="handleModalRegisterClose"
      :typeAuth="OtpConstant.OTP_TYPE_VERIFY_TRANSACTION"
      @data-loaded="handleDataLoaded"
      @submit="handleSubmitOtp">
    </OtpForm>
  </AppMasterVue>
</template>


<script>
import AppMasterVue from '@/layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import {mapGetters} from "vuex";
import helper from "../../../until/helper";
import CallApi from '../../../services/api.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import OtpForm from "@/components/OtpTransaction/OtpForm";
import OtpConstant from "@/constants/OtpConstant";
import PopupRegisterAuth from "@/components/Popups/PopupRegisterAuth";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});


export default {
  components: {
    AppMasterVue,
    PageTitle,
    OtpForm,
    PopupRegisterAuth
  },
  data() {
    return {
      title: 'Title.Withdraw',
      OtpConstant: OtpConstant,
      popupRequestVisible: false,
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.BalanceFluctuationsStatistics",
          routeName: null,
          active: false,
        },
        {
          name: "Title.Withdraw",
          routeName: 'WithdrawBalance',
          active: true,
        }
      ],
      currencyMask,
      amountDisplay: null,
      formState: {
        accountNo: undefined,
        accountName: null,
        bank: null,
        description: null,
        amount: null,
        otp: null,
        accountType: null,
        bankCode: null,
        method: null,
        type: null,
        accountId: undefined
      
      },
      rules: {
        accountId : [
          {required: true, message: 'Account no is required'}
        ],
        amount : [
          { required: true, message: 'Amount is required'}
        ],
        // otp : [
        //   { required: true, message: 'Otp is required'}
        // ]
      },
      disabledSubmit: true,
      accounts: [],
      balance: null,
      minAmount: 1000,
      visibleModal: false,
      loading: false,
      orderCode: null,
      visibleModalRegister: false,
      typeSuccess: false,
      isEnableAuth: false,
      previousFocusedElement: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants'
    })
  },
  mounted() {
    if(!this.isEnableAuth) {
      this.popupRequestVisible = true;
    }

    window.addEventListener('storage', this.handleStorageEvent);
  },
  methods: {
    handleStorageEvent(event) {
      if (event.key === 'componentEvent') {
        const parsedEvent = JSON.parse(event.newValue);
        
        if(!parsedEvent.data.isEnableAuth) {
          this.popupRequestVisible = true;
        }
        
        this.visibleModalRegister = false;
      }
    },
    setAccessibility(isModalVisible) {
      const mainContent = this.$refs.mainContent;
      if (mainContent) {
        if (isModalVisible) {
          mainContent.setAttribute('inert', 'true');
          mainContent.setAttribute('aria-hidden', 'true');
        } else {
          mainContent.removeAttribute('inert');
          mainContent.setAttribute('aria-hidden', 'false');
        }
      }
    },
    handleDataLoaded(data) {
      this.isEnableAuth = data.isEnableAuth;
    },
    handleModalRegisterClose(){
      this.visibleModalRegister = false;
      this.setAccessibility(false);
      if (this.previousFocusedElement) {
        this.previousFocusedElement.focus();
      }
    },
    showModalRegister() {
      this.visibleModalRegister = true;
      this.previousFocusedElement = document.activeElement;
      this.setAccessibility(true);
    },
    handleSubmitOtp(dataFromChild){
      this.formState.method = dataFromChild.method
      this.formState.otp = dataFromChild.otp
      this.formState.type = dataFromChild.type
      this.loading = true;
      CallApi.createRequestWithdraw(this.formState).then((response) => {
        if (response.code) {
          if (response.errors !== 'NotEnableGoogle2FA' && response.errors !== 'InvalidCodeGoogle2FA' && response.errors !== 'NotEnableWithdraw') {
            this.showNotice('error', response.errors, '');
          } else {
            this.showNotice('error', this.$t('Notice.Message.' + response.errors), '');
          }
        } else {
          this.orderCode = response.data.order_code;
          this.handleModalRegisterClose()
          this.loading = false;
          this.showModal();
        }
        this.loading = false;
      });
    },
    isset: helper.isset,
    showMoneyFormat: helper.showMoneyFormat,
    onChangeAccount(accountId){
        let accountInfo = this.accounts.find(function(item) {
            return item.id == accountId;
        })
        this.formState.accountName = accountInfo.account_name;
        this.formState.accountNo = accountInfo.account_number;
        this.formState.bank = accountInfo.bank_name;
        this.formState.accountType = accountInfo.account_type;
        this.formState.bankCode = accountInfo.bank_code;
    },
    onSubmit() {
      this.$refs.formRef.validate(valid => {
        if(valid) {
          this.showModalRegister()
        }
      });
    },
    checkDataFromRequired() {
      if (!this.formState.accountNo || !this.formState.description || !this.formState.amount || this.formState.amount < this.minAmount) {
        this.disabledSubmit = true;
      } else {
        this.disabledSubmit = false;
      }
    },
     onlyAmount($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
     this.maxAmount($event.target.value);
    },
    onlyOtp($event) {
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    if (keyCode < 48 || keyCode > 57) {
      $event.preventDefault();
    }
    },
    resetAmountFixex() {
      let amountOrigin = this.amountDisplay !== null ? this.amountDisplay.replaceAll(',', '') : 0;
      if(parseInt(amountOrigin) < this.minAmount) {
          this.amountDisplay = this.showMoneyFormat(this.minAmount);
      }
        this.maxAmount(this.amountDisplay);
    },

    maxAmount(amountValue) {
      let amountOrigin = amountValue.replaceAll(',', '');
      if (amountOrigin > this.balance) {
            this.amountDisplay = this.showMoneyFormat(this.balance);
      }
    },
    setMaxBalance() {
      this.amountDisplay = this.showMoneyFormat(this.balance);
    },
    validDescription($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        let charCode = String.fromCharCode(keyCode);
        if (!/^[A-Za-z0-9\s]+$/.test(charCode)) {
           $event.preventDefault();
        }
    },
    handleDescription(e) {
      this.formState.description = helper.removeVietnameseTones(e.target.value).trim();
    },
     showModal() {
      this.visibleModal = true;
    },
    closeAlertModal() {
      this.visibleModal = false;
      this.resetForm();
    },

    handleClickViewTransaction() {
       if(this.orderCode) {
          this.$router.push({ name: 'TransactionDisbursementDetail', params: {order_code: this.orderCode}})
       } else {
        this.$router.push({ name: 'TransactionDisbursementList' })
       }
    },
    resetForm () {
      this.formState = {
        accountNo: undefined,
        accountName: null,
        bank: null,
        description: null,
        amount: null,
        otp: null,
        accountId: null,
      };
      this.amountDisplay = null;
    },
    showNotice(type, title, content) {
      this.$notification[type]({
          message: title,
          description: content,
          duration: 5,
          style: {
              "margin-top": "50px"
          },
      });
    },
    loadDataFirst() {
      // set balance
       CallApi.getBalanceAvailable()
      .then(response => {
          if (response.data) {
              this.balance = response.data.disbursement_balance;
          } else {
              this.balance = 'NaN';
          }
      });

       CallApi.getAccountWithdraw()
      .then(response => {
          if (response.data) {
              this.accounts = response.data;
          } else {
              this.accounts = [];
          }
      });
    }
  },
  beforeMount() {
        this.loadDataFirst();
  },
  watch: {
      amountDisplay: function() {
          this.checkDataFromRequired();
          this.formState.amount = this.amountDisplay !== null ? this.amountDisplay.replaceAll(',', '') : 0;
      },
       'formState.description': function() {
          this.checkDataFromRequired();
      },
       'formState.amount': function() {
          this.checkDataFromRequired();
      },
       'formState.otp': function() {
          this.checkDataFromRequired();
      },
  }
}
</script>
<style>
#components-form .ant-row{
  padding-bottom: 0;
}
.color-warning {
 color: #D8811B !important;   
}
#btn-all-amount {
  color: #1589F7;
  font-weight: 700;
  border-left: none;
}
#input-amount + .ant-input-suffix {
  right: 60px;
}
#btn-all-amount {
  position: absolute;
  right: 10px;
  color: #1589F7;
  font-weight: 700;
  cursor: pointer;
}
.alert-icon {
  text-align: center;
  color: #15AE62;
}

.alert-icon > i {
  font-size: 80px;
}

.alert-icon > p {
    position: relative;
    font-size: 20px;
    font-weight: 700;
}
.alert-message {
  font-size: 14px;
}

.alert-footer {
  text-align: center;
}

#btn-close-alert {
  width: 30%;
  border-color: #1565C0;
  color: #1565C0;
}
#btn-show-transaction {
  width: 40%;
  background-color: #1565C0;
  color: #FFFFFF;
}
</style>